import classNames from '../classNames'

export default app => {
  const { body: BODY } = app.dom

  function onClick(e) {
    const btn = e.target.closest(`.${classNames.scrollTo.down}`)
    if (!btn) return

    e.preventDefault()

    const target = btn.closest('section').nextElementSibling
    if (!target) return

    const top = target.getBoundingClientRect().top + BODY.scrollTop

    window.scrollBy({
      top,
      behavior: 'smooth',
    })
  }

  document.addEventListener('click', onClick)
}
