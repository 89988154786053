import animateInView from './animateInView'
import classNames from '../../classNames'

export default ({ dom }) => {
  const importAnimation = async (section, appDom, path) => {
    try {
      const { default: animate } = await import(`${path}`)
      animate(section, appDom)
    } catch (error) {
      console.error('Import error', error)
    }
  }

  const getElements = name => [
    ...document.querySelectorAll(`.${classNames.animatedSection}[data-section="${name}"]`),
  ]

  const getSectionData = (name, pathToScript) => ({
    els: getElements(name),
    onEnter: (section, appDom) => importAnimation(section, appDom, pathToScript),
  })

  const sections = {
    hero: getSectionData('hero', './heroAnimation'),
    quote: getSectionData('quote', './quoteAnimation'),
    services: getSectionData('services', './servicesAnimation'),
    about: getSectionData('about', './aboutAnimation'),
    portfolio: getSectionData('portfolio', './portfolioAnimation'),
    features: getSectionData('features', './featuresAnimation'),
    blog: getSectionData('blog', './blogAnimation'),
    order: getSectionData('order', './orderAnimation'),
    'order-lg': getSectionData('order-lg', './orderLgAnimation'),
    gallery: getSectionData('gallery', './galleryAnimation'),
    'features-full': getSectionData('features-full', './featuresFullAnimation'),
    'blog-page': getSectionData('blog-page', './blogPageAnimation'),
    'section-hero': getSectionData('section-hero', './sectionHeroAnimation'),
    footer: getSectionData('footer', './footerAnimation'),
    'section-block': getSectionData('section-block', './sectionBlockAnimation'),
    stagger: getSectionData('stagger', './animateStagger'),
    'two-cols': getSectionData('two-cols', './twoColsAnimation'),
  }

  Object.values(sections).forEach(({ els, onEnter }) => {
    if (!els.length) return

    els.forEach(section => {
      section.style.opacity = 0

      animateInView({
        section,
        dom,
        onEnter,
      })
    })
  })
}
