import classNames from '../../classNames'

export default async app => {
  const containers = [...document.querySelectorAll(`.${classNames.pricesSheet.wrap}`)]
  if (!containers.length) return

  const { default: PricesSheet } = await import(
    /* webpackChunkName: "PricesSheet" */ './PricesSheet'
  )
  app.pricesSheet = new PricesSheet(containers, app)
  app.pricesSheet.init()
}
