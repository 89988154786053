import { initAppComponent } from '../../helpers'

export default function renderGallerySlider() {
  if (!this.gallerySliders.length) return

  const IS_DESKTOP = window.matchMedia('(min-width: 768px)').matches

  const renderMobileSlider = wrap => {
    const galleryItems = [...wrap.querySelectorAll('.gallery-slide__item')]

    const inner = galleryItems
      .map(
        item => `
          <li class="swiper-slide slide gallery-slide">
            ${item.outerHTML}
          </li>
        `
      )
      .join('')

    wrap.innerHTML = inner
  }

  const renderDesktopSlider = (container, inner) => {
    container.innerHTML = inner
  }

  this.gallerySliders.forEach(item => {
    const { container, inner, isDesktop } = item

    const getSwiperWrapper = () => container.querySelector('.swiper-wrapper')

    const [mySlider] = this.sliders.filter(({ container: c }) => c === container)

    const updateGallery = wrap => {
      initAppComponent({
        app: this.app,
        component: 'gallery',
        method: 'update',
        callback: () => {
          if (this.app.gallery) this.app.gallery.wrapper = wrap
        },
      })
    }

    if (!IS_DESKTOP) {
      if (mySlider) mySlider.destroy()
      renderMobileSlider(getSwiperWrapper())
      if (mySlider) mySlider.init()

      updateGallery(getSwiperWrapper())

      item.isDesktop = false
    } else if (mySlider && !isDesktop) {
      mySlider.destroy()
      renderDesktopSlider(container, inner)
      mySlider.init()

      updateGallery(getSwiperWrapper())

      item.isDesktop = true
    } else {
      item.isDesktop = true
    }
  })
}
