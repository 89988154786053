import { debounce } from 'throttle-debounce'
import MySlider from './MySlider'
import classes from '../../classNames'
import renderGallerySlider from './renderGallerySlider'

const classNames = classes.slider

export default class Slider {
  constructor(slider, app) {
    this.sliderClass = slider
    this.sliders = []
    this.app = app

    this.renderGallerySlider = renderGallerySlider
  }

  get containers() {
    return [...document.querySelectorAll(this.sliderClass)]
  }

  handleResize() {
    this.renderGallerySlider()
  }

  _getOptions() {
    this.getOptions = ({ navigation, pagination }) => ({
      portfolio: {
        init: false,
        slidesPerView: 1,
        grabCursor: true,
        navigation,
        pagination: {
          el: pagination,
          type: 'bullets',
          clickable: true,
        },
        breakpoints: {
          480: {
            pagination: false,
          },
        },
      },
      gallery: {
        init: false,
        slidesPerView: 1,
        spaceBetween: 20,
        noSwiping: false,
        navigation,
        pagination: {
          el: pagination,
          type: 'bullets',
          clickable: true,
        },
        breakpoints: {
          480: {
            pagination: false,
          },
          768: {
            noSwiping: true,
            pagination: false,
          },
        },
      },
    })
  }

  _getSliders() {
    this.gallerySliders = [...document.querySelectorAll(this.sliderClass)]
      .filter(({ dataset: { slider } }) => slider === 'gallery')
      .map(container => ({
        container,
        inner: container.innerHTML,
        isDesktop: undefined, // Set value in renderGallerySlider() method.
      }))
  }

  _renderSliders() {
    this.renderGallerySlider()
  }

  _initSliders() {
    this.containers.forEach(container => {
      if (container.classList.contains(classNames.plugin.initialized)) return

      const slider = new MySlider(container, this.getOptions, this.app)

      slider.init()
      this.sliders = [...this.sliders, slider]
    })
  }

  _addListeners() {
    this.onResize = debounce(300, this.handleResize.bind(this))

    window.addEventListener('resize', this.onResize)
  }

  init() {
    if (!this.containers.length) return

    this._getOptions()
    this._getSliders()
    this._renderSliders()

    this._initSliders()
    this._addListeners()

    // setTimeout(this._initSliders.bind(this), 1000)
  }
}
