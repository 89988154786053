import classNames from '../classNames'

export default function setInputMask(app) {
  const maskedInputs = [...document.querySelectorAll(`.${classNames.mask}`)]

  if (!maskedInputs.length) return

  function getOptions(mask) {
    return {
      phone: {
        mask,
        lazy: true,
        placeholderChar: 'X',
      },
    }
  }

  const init = IMask => {
    app.masks = []
    maskedInputs.forEach(input => {
      const type = input.dataset.maskType || 'phone'
      const mask = input.dataset.mask || '+{38}(000)000-00-00'
      // const staticPrefix = mask.slice(mask.indexOf('{') + 1, mask.indexOf('}'))

      const imask = IMask(input, getOptions(mask)[type])
      app.masks.push(imask)

      let inputValue = ''

      input.addEventListener('input', () => {
        inputValue = imask.masked.rawInputValue
      })
      input.addEventListener('focus', () => {
        imask.updateOptions({ lazy: false })
      })
      input.addEventListener('blur', () => {
        imask.updateOptions({ lazy: true })
        imask.value = inputValue
      })
    })
  }

  if (!('globalThis' in window)) {
    import(/* webpackChunkName: "global-this-polyfill" */ 'core-js/features/global-this').then(
      () => {
        import(/* webpackChunkName: "imask" */ 'imask').then(({ default: IMask }) => init(IMask))
      }
    )
  } else {
    import(/* webpackChunkName: "imask" */ 'imask').then(({ default: IMask }) => init(IMask))
  }
}
