import BEMblock from '../../lib/BEMBlock'
import { IS_ACTIVE, IS_HIDING } from '../../constants'

export default function handleGallerySlider() {
  if (window.matchMedia('(min-width: 480px)').matches) this.handlePaginationNumbers()

  const contentItems = [...this.wrap.querySelectorAll('.slider-bottom__content')]

  const divideIndex = window.matchMedia('(min-width: 768px)').matches ? 1 : 3
  const contentIndex = Math.floor(this.index / divideIndex)
  const currentContentItem = contentItems[contentIndex]

  const activeContent = this.wrap.querySelector('.slider-bottom__content--active')

  Object.values(this.navigation).forEach(btn => {
    btn.setAttribute('disabled', '')
  })

  const handleAnimationEnd = ({ currentTarget }) => {
    BEMblock(currentTarget).removeMod(IS_ACTIVE)
    BEMblock(currentTarget).removeMod(IS_HIDING)
    BEMblock(currentContentItem).addMod(IS_ACTIVE)

    Object.values(this.navigation).forEach(btn => {
      btn.removeAttribute('disabled')
    })

    currentTarget.removeEventListener('animationend', handleAnimationEnd)
  }

  if (
    currentContentItem &&
    activeContent &&
    currentContentItem.innerText !== activeContent.innerText
  ) {
    BEMblock(activeContent).addMod(IS_HIDING)
    activeContent.addEventListener('animationend', handleAnimationEnd)
  }
}
