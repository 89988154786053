import { throttle } from 'throttle-debounce'
import { IS_STICKY } from '../constants'
import BEMblock from '../lib/BEMBlock'

export default ({
  dom: {
    header: { container: header },
  },
}) => {
  const OFFSET = 20
  const handleScroll = () => {
    if (window.pageYOffset > OFFSET) {
      BEMblock(header, 'out__header').addMod(IS_STICKY)
    } else {
      BEMblock(header, 'out__header').removeMod(IS_STICKY)
    }
  }

  const onScroll = throttle(66, handleScroll)
  window.addEventListener('scroll', onScroll)
}
