import classNames from '../classNames'

export default async app => {
  const containers = [...document.querySelectorAll(`.${classNames.tabs.wrap}`)]
  if (!containers.length) return

  const { default: Tabs } = await import(/* webpackChunkName: "tabs" */ '../lib/tabs')

  // app.tabs = new Tabs({
  //   classNames: {
  //     btn: 'prices__title',
  //     item: 'prices__block',
  //   },
  // })

  app.tabs = new Tabs()
  app.tabs.onToggle = () => {
    if (app.pricesSheet) {
      app.pricesSheet.toggleActiveSheet(app.tabs.item)
    }
  }
  app.tabs.init()
}
