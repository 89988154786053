export default {
  'blog-yellow': [
    'M60.0736 135.509C72.6695 105.582 103.288 88.5759 131.991 73.3964C158.366 59.4483 186.968 52.3335 216.803 52.6254C247.739 52.928 276.296 62.4014 304.514 75.0842C342.569 92.1885 392.403 100.419 408.646 138.85C424.923 177.362 401.751 221.445 381.933 258.26C364.054 291.471 337.022 319.263 302.886 335.307C271.675 349.976 236.689 344.112 202.23 342.72C167.36 341.311 128.382 349.158 101.289 327.161C74.2159 305.18 72.7295 265.715 65.3976 231.621C58.4823 199.465 47.3143 165.825 60.0736 135.509Z',
    'm 60.0736,135.509 c 12.5959,-29.927 55.84131,-13.09299 84.54431,-28.27249 26.375,-13.948099 51.44146,-32.679654 81.27646,-32.387754 30.936,0.3026 41.81533,-25.579339 81.65009,-0.269632 38.055,17.1043 52.02858,37.456626 101.10154,64.270876 16.277,38.512 -42.75542,52.7955 -62.57342,89.6105 -17.879,33.211 22.26415,72.11468 -11.87185,88.15868 -31.211,14.669 -108.11833,-18.9742 -142.57733,-20.3662 -34.87,-1.409 -63.2414,52.90502 -90.3344,30.90802 C 74.2159,305.18 116.67114,254.60332 109.33924,220.50932 102.42394,188.35332 47.3143,165.825 60.0736,135.509 Z',
    'm 60.0736,135.509 c 12.5959,-29.927 55.33623,-30.77066 78.98847,-48.475541 26.375,-13.948099 56.9973,-12.476603 86.8323,-12.184703 30.936,0.3026 41.81533,-25.579339 81.65009,-0.269632 38.055,17.1043 70.7164,8.667278 101.10154,64.270876 16.277,38.512 -0.83409,49.76504 -20.65209,86.58004 24.54741,39.27192 7.11186,66.05377 -53.79318,91.18914 -31.211,14.669 -69.22746,-35.13664 -132.98088,-20.87128 C 164.32954,289.1224 128.382,349.158 101.289,327.161 74.2159,305.18 100.5087,240.96626 93.176799,206.87226 86.261499,174.71626 47.3143,165.825 60.0736,135.509 Z',
  ],
  'blog-orange': [
    'M189.959 88.0978C233.175 71.6661 282.709 72.0274 325.262 90.1329C363.886 106.567 378.328 151.267 408.066 180.899C440.432 213.151 495.849 226.698 506.825 271.054C517.941 315.974 492.278 363.852 461.992 398.82C434.608 430.437 388.394 431.718 351.165 450.776C310.698 471.49 280.137 517.501 234.733 515.152C188.087 512.74 148.7 476.698 121.256 438.885C95.5023 403.401 95.1853 358.07 89.2031 314.635C82.9797 269.45 67.2323 222.864 85.7647 181.196C105.17 137.565 145.334 105.066 189.959 88.0978Z',
    'M 171.77625,74.965817 C 214.99225,58.534117 282.709,72.0274 325.262,90.1329 c 38.624,16.4341 24.78173,82.3473 54.51973,111.9793 32.366,32.252 116.06727,24.5858 127.04327,68.9418 11.116,44.92 -53.94295,63.50358 -84.22895,98.47158 -27.384,31.617 -34.20205,62.19242 -71.43105,81.25042 -40.467,20.714 -78.09907,6.11585 -123.50307,3.76685 C 181.01593,452.13085 148.7,476.698 121.256,438.885 95.5023,403.401 139.63201,358.07 133.64981,314.635 127.42641,269.45 57.130774,218.82339 75.663174,177.15539 70.824813,123.42286 127.15125,91.934017 171.77625,74.965817 Z',
    'm 168.7458,50.722155 c 43.216,-16.4317 132.14595,-1.928264 174.69895,16.177236 38.624,16.4341 53.066,64.164559 82.804,93.796559 32.366,32.252 69.60025,66.00205 80.57625,110.35805 11.116,44.92 -14.547,92.798 -44.833,127.766 -27.384,31.617 -0.6696,34.14036 -107.79654,73.1692 C 313.72846,492.7032 280.137,517.501 234.733,515.152 188.087,512.74 148.7,476.698 121.256,438.885 95.5023,403.401 59.82996,388.37458 53.84776,344.93958 47.62436,299.75458 67.2323,222.864 85.7647,181.196 105.17,137.565 124.1208,67.690355 168.7458,50.722155 Z',
  ],
  'blog-red': [
    'm 246.875,58.1114 c 47.567,9.3369 98.927,-6.615 140.73,17.9174 44.035,25.8422 67.399,73.1512 91.305,118.2542 29.079,54.861 70.598,109.354 61.921,170.831 C 531.615,430.412 491.252,495.16 432.274,524.712 375.516,553.151 309.935,519.775 246.875,512.406 192.983,506.108 135.749,514.713 90.5067,484.773 44.2623,454.17 8.8184,404.417 0.801336,349.563 -6.71117,298.16 40.8455,256.794 49.7061,205.606 61.0278,140.202 6.14021,51.3576 59.3056,11.5866 112.175,-27.963 182.077,45.3921 246.875,58.1114 Z',
    'm 256.97653,31.847433 c 47.567,9.3369 88.82547,19.648967 130.62847,44.181367 44.035,25.8422 67.399,73.1512 91.305,118.2542 29.079,54.861 54.43556,86.12049 45.75856,147.59749 -9.216,65.298 -62.71098,89.6399 -121.68898,119.1919 C 346.22158,489.51139 309.935,519.775 246.875,512.406 192.983,506.108 152.92159,492.48964 107.67929,462.54964 61.434893,431.94664 46.194045,400.37639 38.176981,345.52239 30.664475,294.11939 12.561228,252.75339 21.421828,201.56539 32.743528,136.16139 6.14021,51.3576 59.3056,11.5866 112.175,-27.963 192.17853,19.128133 256.97653,31.847433 Z',
    'm 256.97653,31.847433 c 47.567,9.3369 107.00822,2.476374 148.81122,27.008774 44.035,25.8422 76.49037,87.293333 100.39637,132.396333 29.079,54.861 -13.24466,95.21187 -21.92166,156.68887 -9.216,65.298 -22.30488,83.57898 -81.28288,113.13098 -56.758,28.439 -69.81107,10.21529 -132.87107,2.84629 -53.892,-6.298 -87.89249,-21.93666 -133.13479,-51.87666 C 90.729318,381.43902 15.889468,401.38654 7.8724039,346.53254 0.35989791,295.12954 -21.783959,219.41836 -12.923359,168.23036 -1.6016586,102.82636 56.647838,89.743397 109.81323,49.972397 c 52.8694,-39.5496 82.3653,-30.844264 147.1633,-18.124964 z',
  ],
  'order-right': [
    'M563.743 185.476C584.207 231.048 545.801 241.012 534.129 289.585C524.553 329.437 464.034 320.77 434.26 348.937C406.496 375.202 376.592 413.235 339.167 420.984C304.106 428.243 271.551 443.418 235.961 439.5C187.643 434.181 132.654 484.22 99.0689 449.078C64.7351 413.154 58.0144 347.078 70.4638 298.97C82.0533 254.184 234.333 222.462 261.565 185.066C291.207 144.361 296.064 102.365 340.632 78.9305C386.961 54.5697 453.459 73.8215 502.369 92.4643C551.368 111.141 542.263 137.64 563.743 185.476Z',
    'm 620.31154,194.56737 c 20.464,45.572 -74.51054,46.44463 -86.18254,95.01763 -9.576,39.852 -30.69905,92.80431 -60.47305,120.97131 -27.764,26.265 -97.06395,2.67869 -134.48895,10.42769 -35.061,7.259 -37.31142,53.74873 -72.90142,49.83073 -48.318,-5.319 -170.874746,39.20418 -190.430189,-19.71642 -34.3338,-35.924 -59.237246,-127.25382 -46.787846,-175.36182 11.5895,-44.786 111.341265,-155.2999 138.573265,-192.695898 29.642,-40.705 139.55487,-48.355813 184.12287,-71.790313 46.329,-24.3608 141.11127,23.175271 190.02127,41.818071 48.999,18.6767 57.06659,93.66302 78.54659,141.49902 z',
    'm 584.9562,175.37447 c 20.464,45.572 -39.1552,65.63753 -50.8272,114.21053 -9.576,39.852 -35.74981,82.70278 -65.52381,110.86978 -27.764,26.265 -80.90151,-12.47359 -118.32651,-4.72459 -35.061,7.259 -41.35204,8.29186 -76.94204,4.37386 -48.318,-5.319 -161.78337,81.63059 -181.338808,22.70998 -34.3338,-35.924 -30.952974,-63.6142 -18.503574,-111.7222 11.5895,-44.786 43.661042,-23.98007 124.431132,-215.929408 29.642,-40.705 103.18937,0.13151 147.75737,-23.30299 67.54221,-1.127291 122.92853,4.992526 171.83853,23.635326 48.999,18.676702 45.95491,32.043712 67.43491,79.879712 z',
  ],
  'order-left': [
    'M216.741 76.7701C252.952 72.0812 290.535 58.5765 323.56 74.0993C355.487 89.106 361.477 130.89 385.93 156.294C418.067 189.681 482.975 199.239 488.311 245.259C493.476 289.811 440.497 318.16 408.33 349.484C380.927 376.17 398.916 431.785 364.5 448.499C322.749 468.776 210.075 504.721 167 487.5C123.67 470.176 150.229 374.471 123.78 336.068C97.2817 297.591 40.5539 279.462 33.612 233.267C26.4975 185.924 52.5396 135.614 88.9777 104.475C122.929 75.4609 172.441 82.5065 216.741 76.7701Z',
    'm 212.70039,41.41476 c 36.211,-4.6889 93.99705,-15.163142 127.02205,0.359658 31.927,15.0067 82.36371,46.689175 106.81671,72.093172 32.137,33.387 36.43585,85.37141 41.77185,131.39141 5.165,44.552 -7.4079,58.75886 -39.5749,90.08286 -27.403,26.686 -65.98254,125.73756 -100.39854,142.45156 C 306.58656,498.07042 210.075,504.721 167,487.5 123.67,470.176 87.599541,403.76542 61.150541,365.36242 34.652241,326.88542 19.340696,266.33002 12.398796,220.13502 5.2842963,172.79202 52.5396,135.614 88.9777,104.475 122.929,75.4609 168.40039,47.15116 212.70039,41.41476 Z',
    'M 212.70039,74.749795 C 258.57385,9.67051 303.33624,38.487428 334.67168,57.936859 c 28.64806,17.781419 41.58795,70.619641 96.71519,74.113481 32.137,33.387 28.35462,53.04652 33.69062,99.06652 5.165,44.552 15.82561,72.901 -16.34139,104.225 -27.403,26.686 -63.96224,92.40253 -98.37824,109.11653 -41.751,20.277 -73.61279,-19.53944 -116.68779,-36.76044 -43.33,-17.324 -123.84717,-7.97314 -150.296172,-46.37614 -26.4983,-38.477 -28.677862,-75.79889 -35.619762,-121.99389 -7.1145,-47.343 19.937752,-51.18599 56.375854,-82.32499 33.9513,-29.0141 58.20948,-27.01926 108.5704,-82.253135 z',
  ],
  'hero-services': [
    'M613.089 392.769C591.368 444.705 597.532 506.858 559.868 548.691C520.193 592.759 460.919 608.3 404.03 624.974C334.831 645.254 263.148 679.774 195.233 655.559C123.096 629.838 58.8522 568.809 38.9935 494.817C19.8824 423.61 73.1561 356.789 96.2537 286.767C115.993 226.925 119.558 159.804 164.176 115.319C209.782 69.8492 274.655 41.1348 338.932 44.8134C399.163 48.2605 435.128 112.016 491.295 134.041C563.062 162.182 676.944 120.468 709.785 190.233C742.443 259.609 642.679 322.018 613.089 392.769Z',
    'M630.68 433.825C608.959 485.761 630.344 525.991 592.68 567.825C553.005 611.892 486.68 607.825 370.68 629.825C301.481 650.105 234.595 696.04 166.68 671.825C94.5431 646.104 23.9459 563.936 32.68 487.825C46.6801 365.825 93.2872 360.213 116.385 290.192C134.978 233.825 138.701 164.214 184.307 118.744C229.914 73.2741 294.786 44.5598 359.063 48.2384C419.294 51.6855 472.513 96.7198 528.68 118.744C600.447 146.885 673.839 150.061 706.68 219.825C739.338 289.201 660.27 363.074 630.68 433.825Z',
    'M680.346 414.021C658.625 465.957 706.01 524.188 668.346 566.021C628.672 610.088 588.347 602.021 472.347 624.021C403.148 644.302 300.262 712.237 232.347 688.021C160.21 662.3 101.613 606.133 110.347 530.021C124.347 408.021 53.2493 342.043 76.3469 272.021C110.347 168.948 216.741 195.491 262.347 150.021C307.953 104.551 344.133 54.5808 408.41 58.2594C468.641 61.7065 521.86 106.741 578.027 128.765C649.794 156.906 699.506 184.257 732.347 254.021C765.005 323.397 709.936 343.27 680.346 414.021Z',
  ],
  'hero-portfolio': [
    'M320.306 121.414C373.316 107.143 427.659 128.231 478.714 148.474C524.198 166.509 556.36 201.981 595.976 230.718C653.984 272.796 750.323 285.416 764.394 355.702C778.038 423.851 691.143 467.729 655.343 527.253C617.941 589.442 613.672 676.628 550.224 711.8C485.123 747.888 400.66 736.092 332.427 706.259C269.398 678.701 241.812 609.431 196.879 557.308C150.265 503.236 66.4996 466.944 64.6013 395.578C62.6995 324.081 139.335 278.985 188.085 226.716C227.482 184.475 264.54 136.428 320.306 121.414Z',
    'M350.999 112C404.009 97.7285 473.944 91.7566 524.999 112C570.483 130.035 583.966 182.127 623.582 210.863C681.59 252.941 752.927 269.714 766.999 340C780.642 408.149 742.798 477.929 706.998 537.454C669.596 599.642 641.278 656.774 577.83 691.946C512.729 728.034 447.231 683.833 378.999 653.999C315.969 626.441 255 674 155 600C108.386 545.928 76.8977 453.366 74.9994 382C73.0976 310.503 188.249 284.269 236.999 232C276.396 189.759 295.232 127.013 350.999 112Z',
    'M388.161 119.554C441.172 105.283 489.943 170.757 540.998 191C586.483 209.035 683.382 188.263 722.998 217C781.006 259.078 764.926 298.54 778.998 368.826C792.641 436.975 832.798 497.476 796.998 557.001C759.595 619.189 646.446 651.829 582.998 687C517.897 723.089 439.231 688.833 370.998 659C307.969 631.442 260.998 683 160.998 609C114.384 554.928 59.1582 471 112.162 389.554C175 293 126.251 255.095 175 202.826C214.397 160.585 332.395 134.567 388.161 119.554Z',
  ],
  'hero-about': [
    'M674.045 698.746C630.022 731.545 571.733 731.963 516.811 731.955C467.882 731.948 424.913 710.822 377.496 698.704C308.065 680.959 213.854 704.723 174.876 644.566C137.083 586.239 201.697 513.431 213.046 444.904C224.904 373.31 196.748 290.684 242.773 234.61C289.997 177.074 372.864 156.919 447.289 159.513C516.038 161.909 567.206 216.142 628.182 248.042C691.44 281.135 782.684 284.01 810.743 349.655C838.855 415.422 784.226 485.582 758.165 552.135C737.103 605.921 720.356 664.241 674.045 698.746Z',
    'M698.723 667.461C654.7 700.26 583.643 684.213 528.722 684.205C479.792 684.198 354.142 727.58 306.725 715.461C237.294 697.717 210.577 690.182 171.598 630.025C133.805 571.698 160.248 499.986 171.598 431.459C183.455 359.865 170.697 307.535 216.721 251.46C263.946 193.924 360.298 217.475 434.722 220.069C503.471 222.465 609.79 175.56 670.766 207.46C734.024 240.554 704.665 287.816 732.725 353.461C760.836 419.228 822.785 462.907 796.724 529.461C775.662 583.246 745.034 632.956 698.723 667.461Z',
    'M713.389 667.421C669.366 700.22 510.31 668.223 455.389 668.215C406.459 668.208 316.806 708.334 269.389 696.215C189.389 669.421 186.367 652.372 147.389 592.215C109.389 502.215 200.039 464.742 211.389 396.215C223.246 324.621 186.09 290.751 232.115 234.676C288.305 166.215 438.964 191.622 513.389 194.215C582.137 196.611 614.414 184.316 675.39 216.216C738.648 249.309 713.331 290.57 741.391 356.215C769.502 421.982 819.451 471.662 793.39 538.215C772.328 592 759.7 632.916 713.389 667.421Z',
  ],
  'hero-contacts': [
    'M327.893 102.335C381.823 91.3351 434.918 115.848 484.775 139.31C529.192 160.212 559.206 197.756 597.094 229.016C652.57 274.789 748.217 293.432 757.99 364.734C767.466 433.868 677.807 472.424 638.326 529.838C597.078 589.822 587.459 676.917 521.801 708.209C454.431 740.317 370.62 723.239 304.159 689.099C242.766 657.562 219.404 586.437 177.629 531.413C134.292 474.331 52.6812 432.753 55.1577 361.127C57.6388 289.369 137.105 248.953 189.102 199.615C231.123 159.742 271.158 113.906 327.893 102.335Z',
    'M341 89.9999C394.93 79.0002 435.143 90.5381 485 114C529.417 134.902 545.113 206.74 583 238C638.477 283.773 725.227 292.698 735 364C744.476 433.134 642.481 496.586 603 554C561.752 613.984 572.389 664.208 506.731 695.5C439.361 727.607 331.462 729.64 265 695.5C203.608 663.963 182.774 593.024 140.999 538C70.9999 458 68.5234 423.651 71 352.025C73.4811 280.267 153.004 265.645 205 216.307C256.997 166.968 284.265 101.571 341 89.9999Z',
    'M344.129 84.7983C398.059 73.7987 448.143 77.5381 498 101C542.417 121.902 554.113 209.74 592 241C647.477 286.773 708.228 305.698 718.001 377C727.477 446.134 682.531 535 634.001 585C568 653 586.81 695.091 450.001 677C344.129 663 286.462 724.438 220.001 690.298C158.608 658.761 180.001 577.859 118 507C48.0005 427 90.9374 395.363 117.999 329C135.87 285.177 84.0033 234.338 135.999 185C187.996 135.662 287.394 96.3699 344.129 84.7983Z',
  ],
  'categories-notebook': [
    'M43.3624 98.1484C52.5124 76.4082 74.7541 64.0548 95.6053 53.028C114.765 42.8957 135.542 37.7273 157.215 37.9394C179.687 38.1592 200.432 45.041 220.93 54.254C248.575 66.6791 284.775 72.658 296.575 100.575C308.399 128.551 291.566 160.575 277.169 187.318C264.182 211.443 244.545 231.632 219.748 243.287C197.075 253.943 171.66 249.683 146.629 248.672C121.298 247.648 92.9832 253.349 73.3023 237.369C53.6357 221.401 52.556 192.733 47.2299 167.967C42.2064 144.608 34.0937 120.171 43.3624 98.1484Z',
    'M44.9999 98.3336C53.9999 65.0014 87.1488 68.0283 108 57.0015C127.16 46.8692 145.542 35.4854 167.215 35.6975C189.687 35.9173 209.249 55.7883 229.748 65.0014C257.392 77.4264 294.775 70.4161 306.575 98.3336C318.399 126.309 298.397 153.258 284 180.001C271.012 204.127 261.797 229.39 237 241.045C214.327 251.701 200.032 242.056 175 241.045C149.669 240.021 93.6809 257.024 73.9999 241.045C54.3334 225.077 62.326 191.768 56.9999 167.001C51.9764 143.642 35.7312 120.356 44.9999 98.3336Z',
    'M40.3451 111.001C56.3389 73.0005 92.4938 84.0275 113.345 73.0007C132.505 62.8684 144.672 40.7893 166.345 41.0013C188.818 41.2211 213.847 49.7882 234.345 59.0013C261.99 71.4264 298.546 83.084 310.345 111.001C322.169 138.977 292.742 161.258 278.345 188.001C265.357 212.127 273.623 237.028 248.826 248.682C226.153 259.339 183.377 249.694 158.345 248.682C133.015 247.659 119.345 266.999 85.8261 248.682C63.5961 236.534 85.671 206.767 80.3449 182C75.3215 158.641 29.8236 136 40.3451 111.001Z',
  ],
  'categories-folder': [
    'M108.03 44.3766C129.218 35.8486 152.875 42.7224 174.672 49.5442C194.701 55.8127 212.418 66.5772 227.047 81.6255C242.215 97.2291 259.531 115.477 267.169 135.854C277.47 163.334 290.129 193.013 278.975 220.158C267.797 247.36 234.301 257.615 206.096 265.945C180.652 273.46 153.382 273.719 128.464 264.612C105.68 256.285 91.2843 235.904 74.9158 218.001C58.352 199.884 35.1296 184.299 32.7018 159.872C30.2759 135.462 49.2541 115.177 62.6545 94.631C75.2935 75.2527 86.5677 53.0152 108.03 44.3766Z',
    'M106 46.9882C127.188 38.4602 169.203 31.1776 191 37.9994C211.029 44.2679 228 46.9882 246 77.9995C258 102.999 250.362 115.623 258 135.999C268.301 163.48 301.155 193.854 290 220.999C278.822 248.202 248.205 234.669 220 243C194.556 250.514 166.918 283.107 142 273.999C119.216 265.672 108.368 238.903 92 221C75.4362 202.883 34.4277 187.427 32 162.999C29.574 138.59 47.5996 123.545 61 102.999C73.6389 83.6208 84.5375 55.6268 106 46.9882Z',
    'M98 73C113 60 113 34.2293 155 41C197 47.7707 207 55.7175 224 67.9996C241 80.2818 271.362 109.623 279 130C289.301 157.48 284.075 194.636 272.921 221.782C261.743 248.984 235.205 249.669 207 258C181.556 265.515 149.839 283.889 124.921 274.782C102.137 266.455 108.368 232.903 91.9999 215C75.4361 196.883 34.4276 192.427 31.9999 168C29.5739 143.59 32.5996 129.546 46 109C58.6389 89.6217 82.9999 86 98 73Z',
  ],
  'categories-envelope': [
    'M270.592 237.095C257.466 256.693 233.278 264.581 210.708 271.431C189.969 277.726 168.588 278.839 147.353 274.501C125.334 270.002 106.282 259.293 87.9156 246.342C63.1461 228.876 28.749 216.107 22.487 186.453C16.2118 156.736 38.839 128.509 58.0687 105C75.4162 83.7925 98.541 67.7164 125.104 61.0017C149.392 54.8623 173.529 63.8878 197.908 69.6515C222.579 75.484 251.461 75.2844 267.736 94.7219C283.998 114.145 279.594 142.493 280.102 167.82C280.582 191.709 283.888 217.244 270.592 237.095Z',
    'M274.592 239.095C261.466 258.692 242.57 282.149 220 289C199.261 295.295 155 292 135 282C105 260 113.366 245.951 95.0001 233C70.2306 215.534 33.2621 213.654 27.0001 184C20.7249 154.284 42.8389 130.508 62.0686 106.999C79.4161 85.7917 102.541 69.7156 129.104 63.0009C153.392 56.8615 177.529 65.887 201.908 71.6507C226.579 77.4832 255.461 75.5626 271.736 95C287.998 114.423 271.228 146.673 271.736 172C272.215 195.888 287.888 219.243 274.592 239.095Z',
    'M279.048 221.687C266.042 241.106 247.317 264.35 224.952 271.139C204.401 277.376 155.619 282.51 144.719 282.51C133.819 282.51 110.532 277.556 93.1914 221.687C84.2886 193.004 39.9106 196.477 33.7054 167.093C27.4873 137.646 50.8499 122.486 69.905 99.1913C87.0948 78.1764 84.2105 46.39 110.532 39.7364C168.996 24.9579 175.716 21.2891 207.025 65.5002C217.551 80.3639 235.851 88.4641 262.142 99.1913C285.384 108.675 299.293 130.576 299.797 155.673C300.272 179.344 292.223 202.015 279.048 221.687Z',
  ],
  'categories-calendar': [
    'M239.551 275.209C220.504 289.122 195.078 288.244 171.526 286.946C149.886 285.753 129.417 279.472 110.955 268.12C91.8111 256.348 77.5819 239.759 64.7652 221.299C47.4803 196.402 19.541 172.62 23.8195 142.615C28.107 112.548 59.0368 93.7827 85.1579 78.2867C108.722 64.3075 135.956 57.1286 163.212 59.9228C188.133 62.4775 207.715 79.2273 228.644 92.9962C249.823 106.929 277.025 116.639 285.653 140.476C294.276 164.296 280.424 189.419 272.223 213.387C264.488 235.993 258.844 261.115 239.551 275.209Z',
    'M223.836 278.207C204.79 292.12 190.539 299.492 166.987 298.194C145.347 297.001 122.95 289.559 104.487 278.207C85.3435 266.435 83.8039 234.154 70.9873 215.694C53.7023 190.798 10.2088 181.199 14.4873 151.194C18.7748 121.127 52.8662 111.69 78.9873 96.1941C102.552 82.2149 120.241 60.1269 147.497 62.921C172.419 65.4758 184.559 67.425 205.487 81.1938C226.666 95.127 271.422 87.1943 286.987 130.194C300.561 167.694 270.689 191.726 262.487 215.694C254.752 238.3 243.13 264.113 223.836 278.207Z',
    'M232 260C212.954 273.913 198.538 294.492 174.987 293.194C153.346 292.001 130.949 284.56 112.487 273.207C93.3428 261.436 80.3158 244.961 67.4991 226.5C50.2142 201.604 18.208 176.199 22.4865 146.194C26.774 116.127 41.378 109.996 67.4991 94.5002C91.0634 80.521 111.743 54.206 138.999 57.0002C163.92 59.5549 183.571 74.7314 204.5 88.5002C225.678 102.433 261.435 84.5003 277 127.5C290.574 165 296.201 202.532 288 226.5C280.265 249.106 251.293 245.906 232 260Z',
  ],
}
