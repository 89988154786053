export default ({ section, dom, onEnter, once = true, options }) => {
  const rootMargin = window.matchMedia('(min-width: 992px)').matches
    ? '-200px 0px -200px 0px'
    : '-150px 0px -150px 0px'

  if (!options) {
    options = {
      rootMargin,
    }
  }

  function callback(entries, observer) {
    entries.forEach(({ isIntersecting, target }) => {
      if (isIntersecting) {
        onEnter(target, dom)

        if (once) observer.unobserve(target)
      }
    })
  }
  const observer = new IntersectionObserver(callback, options)
  observer.observe(section)
}
