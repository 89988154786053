import classNames from '../classNames'

export default () => {
  const scenes = [...document.querySelectorAll(`.${classNames.parallax}`)]
  if (!scenes.length) return

  import(/* webpackChunkName: "parallax */ 'parallax-js').then(({ default: Parallax }) => {
    scenes.forEach(scene => {
      const style = window.getComputedStyle(scene)
      if (style.display === 'none') return

      const parallaxInstance = new Parallax(scene)

      // const initParallax = () => {
      //   return new Promise(resolve => {
      //     const parallaxInstance = new Parallax(scene, {
      //       onReady: resolve,
      //     })
      //   })
      // }

      // function resetSceneSctyles() {
      //   if (scene.dataset.parallaxLayer === 'true') {
      //     const interval = setInterval(() => {
      //       const img = scene.querySelector('img')
      //       if (img.dataset.loaded) {
      //         scene.style.overflow = 'hidden'

      //         clearInterval(interval)
      //       }
      //     }, 66)
      //   }
      // }

      // initParallax().then(resetSceneSctyles)
    })
  })
}
