import classes from '../../classNames'
import { addZeroToNumber, initAppComponent } from '../../helpers'
import handleGallerySlider from './handleGallerySLider'

const classNames = classes.slider

export default class MySlider {
  constructor(container, getOptions, app) {
    this.app = app
    this.container = container
    this.name = container.dataset.slider
    this.wrap = container.closest(`.${classNames.slider.wrap}`)
    this.sliderEl = container.querySelector(`.${classNames.plugin.wrap}`)
    this.navigation = {
      prevEl: this.wrap.querySelector(`.${classNames.slider.prev}`),
      nextEl: this.wrap.querySelector(`.${classNames.slider.next}`),
    }
    this.pagination = this.wrap.querySelector(`.${classNames.slider.pagination}`)

    this.nameMod = undefined // if need to reinit slider with different options

    this.options = getOptions({
      navigation: this.navigation,
      pagination: this.pagination,
    })[this.nameMod || this.name]

    this.handleGallerySlider = handleGallerySlider
  }

  get slides() {
    return [...this.container.querySelectorAll(`.${classNames.slider.slide}`)]
  }

  get activeSlide() {
    return this.slides.filter(slide =>
      slide.classList.contains(`${classNames.plugin.activeSlide}`)
    )[0]
  }

  get index() {
    return this.slides.indexOf(this.activeSlide)
  }

  handlePaginationNumbers() {
    const current = this.pagination.querySelector('.swiper-pagination-current')

    if (current) current.innerHTML = addZeroToNumber(this.index + 1)
  }

  renderPagination() {
    const total = addZeroToNumber(this.slides.length)
    const current = addZeroToNumber(this.index + 1)

    const pagination = `
      <span class="swiper-pagination-current">${current}</span>
      /
      <span class="swiper-pagination-total">${total}</span>
    `

    this.pagination.innerHTML = pagination
  }

  handlePortfolioSlider() {
    if (window.matchMedia('(min-width: 480px)').matches) this.handlePaginationNumbers()
  }

  handlePortfolioSliderInit() {
    initAppComponent({
      app: this.app,
      component: 'lazyLoader',
      method: 'update',
    })

    if (window.matchMedia('(min-width: 480px)').matches) this.renderPagination()
  }

  handleGallerySliderInit() {
    initAppComponent({
      app: this.app,
      component: 'lazyLoader',
      method: 'update',
    })

    if (window.matchMedia('(min-width: 480px)').matches) this.renderPagination()
  }

  async _initPlugin() {
    const { default: Swiper } = await import(/* webpackChunkName: "swiper" */ 'swiper/js/swiper')
    this.swiper = new Swiper(this.container, this.options)

    if (this.name === 'gallery') {
      this.swiper.on('init', this.handleGallerySliderInit.bind(this))
      this.swiper.on('transitionStart', this.handleGallerySlider.bind(this))
      this.swiper.init()
    }

    if (this.name === 'portfolio') {
      this.swiper.on('init', this.handlePortfolioSliderInit.bind(this))
      this.swiper.on('transitionEnd', this.handlePortfolioSlider.bind(this))
      this.swiper.init()
    }
    this.inited = true
  }

  destroy(deleteInstance, cleanStyles) {
    if (!this.swiper.destroy) return
    this.swiper.destroy(deleteInstance, cleanStyles)
    this.sliderEl.style.transform = ''

    this.inited = false
  }

  init() {
    this._initPlugin()
  }
}
