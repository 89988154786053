import { debounce } from 'throttle-debounce'

export default ({ dom: { layout } }) => {
  const btn = document.querySelector('.main__btn')

  if (!btn) return

  const setPadding = () => {
    const offset = btn.offsetHeight
    layout.style.paddingBottom = `${offset}px`
  }

  const onResize = debounce(200, setPadding)

  setPadding()
  window.addEventListener('resize', onResize)
}
