export default {
  lazy: 'js-lazy',
  animatedSection: 'js-animated-section',
  layerImage: 'js-layer-image',
  parallax: 'js-parallax',
  bubble: 'js-bubble',
  popup: 'js-popup',
  mask: 'js-input-mask',
  dropdown: 'js-dropdown',
  lightgallery: {
    wrap: 'js-lightgallery',
    trigger: 'js-lightgallery-trigger',
  },
  lottie: {
    arrow: 'js-lottie-arrow',
  },
  menu: {
    burger: 'js-burger',
    menu: 'js-menu',
  },
  scrollTo: {
    down: 'js-scroll-down',
  },
  slider: {
    container: 'js-slider',
    slider: {
      wrap: 'slider__wrap',
      prev: 'js-slider-prev',
      next: 'js-slider-next',
      pagination: 'js-slider-pagination',
      slide: 'swiper-slide',
    },
    plugin: {
      wrap: 'swiper-wrapper',
      container: 'swiper-container',
      initialized: 'swiper-container-initialized',
      activeSlide: 'swiper-slide-active',
    },
  },
  tabs: {
    wrap: 'js-tabs',
    tab: 'js-tabs-tab',
    item: 'js-tabs-item',
  },
  pricesSheet: {
    wrap: 'js-prices-sheet',
    nav: 'js-prices-sheet-nav',
    content: 'js-prices-sheet-content',
  },
}
