import classNames from '../classNames'
import { IS_OPEN } from '../constants'
import BEMblock from '../lib/BEMBlock'

export default () => {
  const dropdowns = document.querySelectorAll(`.${classNames.dropdown}`)
  if (!dropdowns.length) return

  const onClick = ({ target }) => {
    const wrap = target.closest(`.${classNames.dropdown}`)
    const option = target.closest('.dropdown-option')

    if (wrap) {
      const opener = wrap.querySelector('.dropdown__opener')
      if (target === opener) BEMblock(wrap, 'dropdown').toggleMod(IS_OPEN)

      if (option) BEMblock(wrap, 'dropdown').removeMod(IS_OPEN)
    } else {
      dropdowns.forEach(dropdown => BEMblock(dropdown, 'dropdown').removeMod(IS_OPEN))
    }
  }

  document.addEventListener('click', onClick)
}
