// import Bubble from './Bubble'
import bubblesPaths from './paths'
import { isTouch } from '../../helpers'

export default class SvgBubbles {
  constructor(className) {
    this.className = className
    this.allBubbles = []
  }

  onIntersecting(entries) {
    entries.forEach(entry => {
      const [bubble] = this.allBubbles.filter(b => b.el === entry.target)

      if (entry.isIntersecting) {
        bubble.start()
      } else if (bubble) bubble.stop()
    })
  }

  async _initBubles() {
    if (!isTouch) this.observer = new IntersectionObserver(this.onIntersecting.bind(this))

    const { default: Bubble } = await import(/* webpackChunkName: "bubble" */ './Bubble')

    this.elements.forEach(el => {
      const bubble = new Bubble({ el, paths: bubblesPaths })
      bubble.init()

      this.allBubbles.push(bubble)

      if (!isTouch) this.observer.observe(el)
    })
  }

  init() {
    this.elements = [...document.querySelectorAll(this.className)]
    if (!this.elements.length) return

    this._initBubles()
  }
}
