export const {
  IS_ACTIVE,
  IS_CURRENT,
  IS_OPEN,
  IS_READY,
  IS_LOADED,
  NO_SCROLL,
  NO_TOUCH,
  IS_TOUCH,
  IS_HIDING,
  IS_STICKY,
  HAS_ERROR,
} = {
  IS_ACTIVE: 'active',
  IS_CURRENT: 'current',
  IS_OPEN: 'open',
  IS_READY: 'is-ready',
  IS_LOADED: 'loaded',
  NO_SCROLL: 'no-scroll',
  NO_TOUCH: 'no-touch',
  IS_TOUCH: 'is-touch',
  IS_HIDING: 'hiding',
  IS_STICKY: 'sticky',
  HAS_ERROR: 'error',
}
