import regeneratorRuntime from 'regenerator-runtime'
import './public-path'
import loadPolyfills from './polyfills/loadPolyfills'
import classNames from './classNames'
import { isModernBrowser } from './helpers'

import setHTMLClassNames from './methods/setHTMLClassNames'
import scrollTo from './methods/scrollTo'
import animateSections from './methods/animations'
import setParallax from './methods/setParallax'
import handlePreloader from './methods/handlePreloader'
import setTabs from './methods/setTabs'
import checkBottomButton from './methods/checkBottomButton'
import setPopups from './methods/setPopups'
import setPricesSheet from './components/PricesSheet/setPricesSheet'
import setInputMask from './methods/setInputMask'
import toggleStickyHeader from './methods/toggleStickyHeader'
import toggleDropdown from './methods/toggleDropdown'

import Menu from './components/Menu/Menu'
import Slider from './components/Slider/Slider'
import SvgBubbles from './components/SvgBubbles/SvgBubbles'
import setLazy from './components/LazyLoader/setLazy'
import setGallery from './components/Gallery/setGallery'

class App {
  constructor() {
    this.methods = {}
    this.classNames = classNames
    this.dom = {
      body: document.body,
      layout: document.querySelector('.out'),
      header: {
        container: document.querySelector('.header'),
        stagger: document.querySelectorAll('.header .nav__item, .header .header__contacts a'),
        logo: document.querySelectorAll('.header .header__logo'),
      },
    }
    this.state = {
      hasMenuOpen: false,
    }

    this.menu = new Menu(this, {
      classNames: {
        btn: 'burger',
        menu: 'header__body',
      },
    })
    this.slider = new Slider(`.${classNames.slider.container}`, this)
    this.bubbles = new SvgBubbles(`.${classNames.bubble}`)
  }

  updateState(state) {
    this.state = {
      ...this.state,
      ...state,
    }
  }

  initMethods() {
    this.methods = {
      setHTMLClassNames,
      setLazy,
      scrollTo,
      animateSections,
      setParallax,
      setTabs,
      checkBottomButton,
      setPopups,
      setGallery,
      setPricesSheet,
      setInputMask,
      toggleStickyHeader,
      toggleDropdown,
    }

    Object.values(this.methods).forEach(fn => fn(this))
  }

  init() {
    this.initMethods()

    this.menu.init()
    this.slider.init()
    this.bubbles.init()
  }
}

const init = () => {
  function mainInit() {
    return new Promise(resolve => {
      const app = new App()
      app.init()
      window.app = app

      // Kludge with hardcoded delay to hide preloader.
      setTimeout(resolve, 500)
    })
  }

  handlePreloader(mainInit)
}

if (isModernBrowser) {
  document.addEventListener('DOMContentLoaded', init)
} else {
  document.addEventListener('DOMContentLoaded', loadPolyfills.bind(null, init))
}
