import classNames from '../../classNames'

export default async app => {
  const lgs = [...document.querySelectorAll(`.${classNames.lightgallery.wrap}`)]

  if (!lgs.length) return

  const { default: Gallery } = await import(/* webpackChunkName: "Gallery" */ './Gallery')

  lgs.forEach(lg => {
    app.gallery = new Gallery(lg)
    app.gallery.init()
  })
}
